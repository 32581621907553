import gql from 'graphql-tag'

export const ValidationUpdateFragment = gql`
  fragment ValidationUpdate on Validation {
    name
    validationTypeId
    options
  }
`

export const ValidationCreateFragment = gql`
  fragment ValidationCreate on Validation {
    _id
    environmentId
    createdAt
  }
`

export const ValidationFragment = gql`
  fragment Validation on Validation {
    ...ValidationCreate
    ...ValidationUpdate
  }

  ${ValidationCreateFragment}
  ${ValidationUpdateFragment}
`
