






















































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import gql from 'graphql-tag'
import { ValidationFragment, ValidationUpdateFragment } from './fragments'
import { Validation, ValidationType } from '@/models'
import Loading from '@/components/Loading.vue'
import Fields from '@/components/form/Fields.vue'
import OptionFields from '@/components/form/OptionFields.vue'
import _cloneDeep from 'lodash/cloneDeep'
import _isEqual from 'lodash/isEqual'
import cleanData from '@/utils/gql/cleanData'
import ComponentSelect from '@/components/fields/componentSelect/Field.vue'
import CollectionFieldSelect from '@/components/fields/collectionFieldSelect/Field.vue'
import { confirmDelete } from '@/components/dialogs'

@Component({
  components: {
    ComponentSelect,
    CollectionFieldSelect,
    Loading,
    Fields,
    OptionFields
  },
  apollo: {
    savedValidation: {
      query: gql`
        query getValidation($validationId: ID) {
          savedValidation: validation(validationId: $validationId) {
            ...Validation
          }
        }
        ${ValidationFragment}
      `,
      variables() {
        return {
          validationId: this.componentId
        }
      }
    },
    validationTypes: gql`
      query {
        validationTypes {
          _id
          name
          optionsParams
        }
      }
    `
  }
})
export default class ValidationEdit extends Vue {
  @Prop({ type: String, required: true }) environmentId!: String
  @Prop({ type: String, required: true }) componentId!: String

  savedValidation: Validation | null = null
  validationTypes: ValidationType[] = []
  validation: Partial<Validation> = {}

  saving = false

  get validationOptions() {
    if (this.validation.options === null) {
      return {}
    } else {
      return this.validation.options
    }
  }

  set validationOptions(options) {
    this.validation.options = options
  }

  @Watch('savedValidation')
  updateSavedValidation(savedValidation: Validation) {
    this.$set(this, 'validation', _cloneDeep(savedValidation))
  }

  @Watch('validation.name')
  updateName(newName: string) {
    this.$emit('name', newName)
  }

  /** Resets options on each type change so the validation doesn't get stuck. */
  @Watch('validation.validationTypeId')
  clearOptions(newType: string, oldType?: string | null) {
    if (newType === oldType || oldType == null) return
    this.validation.options = {}
  }

  get validationType() {
    if (!this.validation.validationTypeId) return
    return this.validationTypes.find(
      (t) => t._id === this.validation.validationTypeId
    )
  }

  get availableValidationTypes() {
    return this.validationTypes.map((validationType) => ({
      label: validationType.name,
      value: validationType._id
    }))
  }

  get dirty() {
    return !_isEqual(this.validation, this.savedValidation)
  }

  async save() {
    if (!this.validation || this.saving) return
    this.saving = true
    try {
      const result = await this.$apollo.mutate({
        mutation: gql`
          mutation ($validationId: ID, $validation: UpdateValidationInput) {
            updateValidation(
              validationId: $validationId
              validation: $validation
            ) {
              ...Validation
            }
          }
          ${ValidationFragment}
        `,
        // Parameters
        variables: {
          validationId: this.validation._id,
          validation: cleanData(this.validation, ValidationUpdateFragment)
        }
      })

      this.savedValidation = result.data.updateValidation
      this.$emit('save', result.data.updateValidation)
    } catch (e) {
      this.$emit('error', e)
      console.error(e)
    } finally {
      this.saving = false
    }
  }

  async deleteItem() {
    if (
      !(await confirmDelete(
        this.$t('validations_edit.script.deleteItem.confirm').toString()
      ))
    )
      return
    if (!this.validation || this.saving) return
    this.saving = true
    try {
      const result = await this.$apollo.mutate({
        mutation: gql`
          mutation ($validationId: ID) {
            deleteValidation(validationId: $validationId)
          }
        `,
        // Parameters
        variables: {
          validationId: this.validation._id
        }
      })
      this.$emit('delete', result.data.deleteValidation)
    } catch (e) {
      this.$emit('error', e)
      console.error(e)
    }
  }

  dismiss() {
    this.$emit('dismiss')
  }
}
